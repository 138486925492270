import { Box, Spinner, Image } from '@chakra-ui/react';
import type { YouTubePlayerProps } from 'react-player/youtube';

import { lazy } from 'components/lazy';
import { toSentence } from 'lib/utils/string';
import { parseUrl } from 'lib/utils/url';

const lazyFallback = (
  <Box h="full" display="flex" alignItems="center" justifyContent="center">
    <Spinner size="xl" thickness="4px" color="gray.400" />
  </Box>
);

const PdfViewer = lazy(
  () => import('components/PdfViewer').then((d) => d.PdfViewer),
  lazyFallback,
);

const ReactPlayerYoutube = lazy(
  () => import('react-player/youtube'),
  lazyFallback,
);

export const YoutubeEmbed: React.FC<
  YouTubePlayerProps & {
    url: string;
    autoPlay?: boolean;
  }
> = ({ url, autoPlay = true, ...rest }) => {
  return (
    <ReactPlayerYoutube
      url={url}
      width="100%"
      height="100%"
      fallback={lazyFallback}
      playing={autoPlay}
      controls
      config={{
        playerVars: { autoplay: autoPlay ? 1 : undefined, modestbranding: 1 },
        embedOptions: { host: 'https://www.youtube-nocookie.com' },
      }}
      {...rest}
    />
  );
};

const IframeEmbed: React.FC<{ url: string; title?: string }> = ({
  url,
  title,
}) => {
  return (
    <iframe
      src={url}
      title={title}
      width="100%"
      height="100%"
      allowFullScreen
    />
  );
};

const EMBED_TYPES = {
  image: 'image',
  pdf: '.pdf',
  microsoft_doc: 'Word Doc',
  microsoft_ppt: 'PowerPoint',
  google_drive: 'Google Drive file',
  google_slides: 'Google Slides',
  google_doc: 'Google Doc',
  youtube: 'YouTube video',
};

type EmbedType = keyof typeof EMBED_TYPES;

export const EMBED_TYPES_LABEL = (
  toSentence(Object.values(EMBED_TYPES), 'or') as string[]
).join('');

export const parseEmbeddableUrl = (
  url: string,
  title?: string | null,
  {
    forceGview,
    fileType,
  }: {
    /**
     * Use google's PDF viewer instead of our own. This is useful for PDFs that
     * don't render properly in our viewer or are cross-origin
     */
    forceGview?: boolean;
    fileType?: string;
  } = {},
): {
  type: EmbedType;
  title?: string;
  url: string;
  component: React.FC<{ url: string; title?: string; autoPlay?: boolean }>;
} | null => {
  title ||= 'lesson content embed';

  const parsed = parseUrl(url);
  if (!parsed) return null;

  const filename = parsed.pathname.split('/').pop();
  const ext = filename?.split('.').pop();
  fileType ||= ext;

  let gviewType: EmbedType | undefined;
  if (fileType === 'docx' || fileType === 'doc') gviewType = 'microsoft_doc';
  else if (fileType === 'pptx' || fileType === 'ppt')
    gviewType = 'microsoft_ppt';
  else if (forceGview) {
    if (fileType === 'pdf') gviewType = 'pdf';
  }

  if (gviewType) {
    return {
      type: gviewType,
      title,
      url: `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
        parsed.href,
      )}`,
      component: IframeEmbed,
    };
  }

  if (
    fileType === 'png' ||
    fileType === 'jpg' ||
    fileType === 'jpeg' ||
    fileType === 'webp' ||
    fileType === 'gif'
  ) {
    return {
      type: 'image',
      title,
      url: parsed.href,
      component: (props) => (
        <Image src={props.url} alt={props.title || ''} h="full" mx="auto" />
      ),
    };
  }

  if (
    parsed.host === 'docs.google.com' &&
    parsed.pathname.startsWith('/presentation/')
  ) {
    parsed.pathname = parsed.pathname.replace(/\/(edit|preview)$/, '/embed');

    return {
      type: 'google_slides',
      title,
      url: parsed.href,
      component: IframeEmbed,
    };
  }

  if (
    parsed.host === 'docs.google.com' &&
    parsed.pathname.startsWith('/document/')
  ) {
    parsed.pathname = parsed.pathname.replace(/\/(edit|preview)$/, '/pub');
    parsed.search = '?embedded=true';

    return {
      type: 'google_doc',
      title,
      url: parsed.href,
      component: IframeEmbed,
    };
  }

  // this is a file in google drive. Probably a PDF
  if (
    parsed.host === 'drive.google.com' &&
    parsed.pathname.startsWith('/file/d/') &&
    parsed.pathname.endsWith('/preview')
  ) {
    return {
      type: 'google_drive',
      title,
      url: parsed.href,
      component: IframeEmbed,
    };
  }

  if (fileType === 'pdf') {
    return {
      type: 'pdf',
      title,
      url: parsed.href,
      component: (props) => <PdfViewer fallback={lazyFallback} {...props} />,
    };
  }

  // https://stackoverflow.com/a/7362089/6656308
  if (/https?:\/\/(www\.)?(youtube|youtu\.be)/.test(parsed.href)) {
    const youtubeId = parsed.href.match(/(?:v\/|v=|youtu\.be\/)([^?&]+)/)?.[1];
    if (youtubeId) {
      return {
        type: 'youtube',
        title,
        url: parsed.href,
        component: YoutubeEmbed,
      };
    }
  }

  return null;
};
