import * as _ from 'lodash-es';
import { useMemo } from 'react';
import { z, ZodObject, ZodSchema } from 'zod';

import { useLocation, Router } from 'components/Link';
import { decodeQuery, encodeQuery, InputQueryObj } from 'lib/utils';

export const updateQuery = <Q extends InputQueryObj>(
  updates: NullableValues<Q> | ((prev: unknown) => NullableValues<Q>),
  /** Set to false to create a new item on the history stack (default: true) */
  replace = true,
  /** Set to true to prevent page data from refreshing (see app/javascript/lib/pageData.tsx) (default: false) */
  shallow = false,
) => {
  const loc = window.location;

  const prevQuery = decodeQuery(loc.search);
  const newQuery = _.isFunction(updates)
    ? updates(prevQuery)
    : {
        ...prevQuery,
        ...updates,
      };

  Router[replace ? 'replace' : 'push']({
    hash: loc.hash,
    pathname: loc.pathname,
    search: encodeQuery(newQuery),
    state: shallow ? { shallow: true } : undefined,
  });
};

export const useUrlQuery = <Q extends Record<string, string | undefined>>() => {
  const location = useLocation();
  const query = useMemo(
    () => decodeQuery<Q>(location.search),
    [location.search],
  );

  return [
    query,
    updateQuery as typeof updateQuery<{
      [K in keyof Q]: InputQueryObj[string];
    }>,
  ] as const;
};

type GetRecordValue<T extends Record<string, unknown>> = T[keyof T];

type NullableValues<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] | null | undefined;
};

/**
 * NOTE: All fields must be optional.
 */
export const useUrlFilters = <
  Schema extends ZodObject<any, any, ZodSchema<GetRecordValue<InputQueryObj>>>,
>(
  zodSchema: Schema,
) => {
  type QType = z.infer<Schema>;

  const location = useLocation();

  const query = useMemo(() => {
    const decoded = decodeQuery(location.search);
    const parsed = zodSchema.safeParse(decoded);
    return (parsed.success ? parsed.data : {}) as QType;
  }, [zodSchema, location.search]);

  return [
    query,
    updateQuery as (updates: NullableValues<QType>) => void,
  ] as const;
};
